//@author: kate

import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";

export class KeyboardSortableService {
    public static lastElementButton: any = null;
    public static onArrowKey(
        event: any,
        sortableArray: any[],
        compareVar: string,
        compareValue: any,
        customUpDownFn: any,
    ) {
        let sortableHandle = $(event.target);

        jSith.addClass(sortableHandle[0].parentElement, "selectingElement");

        if (this.lastElementButton && sortableHandle[0] !== this.lastElementButton) {
            jSith.removeClass(this.lastElementButton.parentElement, "selectingElement");
        }

        this.lastElementButton = sortableHandle[0];

        const defaultUpDown = (sortableHandle: any, sortableArray: any[], compareValue: any, compareVar: string) => {
            let elementIndex: number;
            for (let i = 0; i < sortableArray.length; i++) {
                if (S25Util.propertyGet(sortableArray[i], compareVar) === compareValue) {
                    elementIndex = i;
                }
            }

            sortableHandle[0].addEventListener(
                "keydown",
                function (event: any) {
                    if (event.key === "ArrowUp") {
                        if (elementIndex !== 0) {
                            let elem = sortableArray.splice(elementIndex, 1);
                            if (elem && elem.length) {
                                sortableArray.splice(elementIndex - 1, 0, elem[0]);
                                sortableHandle.focus();
                            }
                        }
                    }
                    if (event.key === "ArrowDown") {
                        if (elementIndex <= sortableArray.length - 1) {
                            let elem = sortableArray.splice(elementIndex, 1);
                            if (elem && elem.length) {
                                sortableArray.splice(elementIndex + 1, 0, elem[0]);
                                sortableHandle.focus();
                            }
                        }
                    }
                },
                {
                    once: true,
                },
            );
        };

        customUpDownFn = customUpDownFn || defaultUpDown;
        customUpDownFn(sortableHandle, sortableArray, compareVar, compareValue);
    }
}
