//@author devin
import { MasterDefinition } from "../master.definition";

export class OrganizationCategoryService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/orgcat.json";
    private static rootNodeName = "organization_categories";
    private static itemNodeName = "category";
    private static idNodeName = "category_id";
    private static nameNodeName = "category_name";

    public constructor() {
        super(
            OrganizationCategoryService.serviceUrl,
            OrganizationCategoryService.rootNodeName,
            OrganizationCategoryService.itemNodeName,
            OrganizationCategoryService.idNodeName,
            OrganizationCategoryService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (OrganizationCategoryService.singleton == null) {
            OrganizationCategoryService.singleton = new OrganizationCategoryService();
        }
        return OrganizationCategoryService.singleton;
    }
}
