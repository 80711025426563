//@author devin
import { MasterDefinition } from "../master.definition";

export class CustomAttributeContactService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/conat.json";
    private static rootNodeName = "contact_custom_attributes";
    private static itemNodeName = "attribute";
    private static idNodeName = "attribute_id";
    private static nameNodeName = "attribute_name";

    public constructor() {
        super(
            CustomAttributeContactService.serviceUrl,
            CustomAttributeContactService.rootNodeName,
            CustomAttributeContactService.itemNodeName,
            CustomAttributeContactService.idNodeName,
            CustomAttributeContactService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (CustomAttributeContactService.singleton == null) {
            CustomAttributeContactService.singleton = new CustomAttributeContactService();
        }
        return CustomAttributeContactService.singleton;
    }
}
