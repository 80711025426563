//@author devin
import { MasterDefinition } from "../master.definition";

export class CustomAttributeOrganizationService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/orgat.json";
    private static rootNodeName = "organization_custom_attributes";
    private static itemNodeName = "attribute";
    private static idNodeName = "attribute_id";
    private static nameNodeName = "attribute_name";

    public constructor() {
        super(
            CustomAttributeOrganizationService.serviceUrl,
            CustomAttributeOrganizationService.rootNodeName,
            CustomAttributeOrganizationService.itemNodeName,
            CustomAttributeOrganizationService.idNodeName,
            CustomAttributeOrganizationService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (CustomAttributeOrganizationService.singleton == null) {
            CustomAttributeOrganizationService.singleton = new CustomAttributeOrganizationService();
        }
        return CustomAttributeOrganizationService.singleton;
    }
}
