//@author devin
import { MasterDefinition } from "../master.definition";

export class LocationCategoryService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rmcat.json";
    private static rootNodeName = "space_categories";
    private static itemNodeName = "category";
    private static idNodeName = "category_id";
    private static nameNodeName = "category_name";

    public constructor() {
        super(
            LocationCategoryService.serviceUrl,
            LocationCategoryService.rootNodeName,
            LocationCategoryService.itemNodeName,
            LocationCategoryService.idNodeName,
            LocationCategoryService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (LocationCategoryService.singleton == null) {
            LocationCategoryService.singleton = new LocationCategoryService();
        }
        return LocationCategoryService.singleton;
    }
}
