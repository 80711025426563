//@author devin
import { MasterDefinition } from "../master.definition";
import { MasterDefinitionUtil } from "../../../util/master.definition.util";

export class LocationFeatureService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rmfeat.json";
    private static rootNodeName = "space_features";
    private static itemNodeName = "feature";
    private static idNodeName = "feature_id";
    private static nameNodeName = "feature_name";

    public constructor() {
        super(
            LocationFeatureService.serviceUrl,
            LocationFeatureService.rootNodeName,
            LocationFeatureService.itemNodeName,
            LocationFeatureService.idNodeName,
            LocationFeatureService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (LocationFeatureService.singleton == null) {
            LocationFeatureService.singleton = new LocationFeatureService();
        }
        return LocationFeatureService.singleton;
    }

    public setSchedule25(itemId: number, schedule25: boolean) {
        return MasterDefinitionUtil.setValues(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            itemId,
            {
                s25_feature: schedule25 ? "T" : "F",
                s25_feature_number: schedule25 ? "1" : "",
            },
        );
    }
}
