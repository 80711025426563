//@author devin
import { MasterDefinitionUtil } from "../../util/master.definition.util";

export class MasterDefinition {
    public readonly serviceUrl: string;
    public readonly rootNodeName: string;
    public readonly itemNodeName: string;
    public readonly idNodeName: string;
    public readonly nameNodeName: string;

    public constructor(
        serviceUrl: string,
        rootNodeName: string,
        itemNodeName: string,
        idNodeName: string,
        nameNodeName: string,
    ) {
        this.serviceUrl = serviceUrl;
        this.rootNodeName = rootNodeName;
        this.itemNodeName = itemNodeName;
        this.idNodeName = idNodeName;
        this.nameNodeName = nameNodeName;
    }

    public get(urlAddOn?: any) {
        let url = this.serviceUrl;
        if (urlAddOn) {
            url += urlAddOn;
        }
        return MasterDefinitionUtil.get(url, this.rootNodeName, this.itemNodeName);
    }

    public delete(itemId: number) {
        return MasterDefinitionUtil.delete(this.serviceUrl, this.idNodeName, itemId);
    }

    public forceDelete(itemId: number) {
        return MasterDefinitionUtil.forceDelete(this.serviceUrl, this.idNodeName, itemId);
    }

    public setName(itemId: number, itemName: string) {
        return MasterDefinitionUtil.setName(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            this.nameNodeName,
            itemId,
            itemName,
        );
    }

    public setActive(itemId: number, active: boolean) {
        return MasterDefinitionUtil.setActive(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            itemId,
            active,
        );
    }

    public createNew(paramMap?: any) {
        return MasterDefinitionUtil.createNew(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            this.nameNodeName,
            paramMap,
        );
    }
}
