//@author devin
import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { S25Util } from "../util/s25-util";

export class NotificationPolicyService {
    public static ItemTypeIdMap: any = {
        2: {
            serviceUrl: "/org_notify.json",
            idNodeName: "organization_id",
        },

        4: {
            serviceUrl: "/space_notify.json",
            idNodeName: "space_id",
        },

        6: {
            serviceUrl: "/resource_notify.json",
            idNodeName: "resource_id",
        },

        8: {
            serviceUrl: "/evreq_notify.json",
            idNodeName: "service_id",
        },

        7: {
            serviceUrl: "/ev_type_notify.json",
            idNodeName: "type_id",
        },
    };

    @Timeout
    public static get(itemTypeId: number, itemId: number) {
        let map = NotificationPolicyService.ItemTypeIdMap[itemTypeId];
        return DataAccess.get(map.serviceUrl + "?" + map.idNodeName + "=" + itemId).then(function (data) {
            return (
                data &&
                data.notify_info &&
                data.notify_info.notification_policy &&
                data.notify_info.notification_policy.length &&
                S25Util.prettifyJson(data.notify_info.notification_policy[0], null, { contact: true })
            );
        });
    }

    public static delete(itemTypeId: number, itemIds: number | number[]) {
        itemIds = S25Util.array.forceArray(itemIds) as number[];
        let map = NotificationPolicyService.ItemTypeIdMap[itemTypeId];
        return DataAccess.delete(map.serviceUrl + "?" + map.idNodeName + "=" + itemIds.join("+"));
    }

    public static set(
        itemTypeId: number,
        itemIds: number | number[],
        approvalTypeId: number,
        days: number,
        hours: number,
        minutes: number,
        contacts: any[],
        isNew: boolean,
        useBulk?: boolean,
    ) {
        let respondWithin = "P" + days + "DT" + hours + "H" + minutes + "M";
        itemIds = S25Util.array.forceArray(itemIds) as number[];

        let addContacts = contacts.filter((c) => c.status != "del");
        if (useBulk) {
            return NotificationPolicyService.setBulk(
                itemTypeId,
                itemIds,
                approvalTypeId,
                respondWithin,
                addContacts,
                null,
                true,
            );
        } else {
            let map = NotificationPolicyService.ItemTypeIdMap[itemTypeId];
            let method = isNew ? DataAccess.post : DataAccess.put;
            let status = isNew ? "new" : "mod";

            let itemIdsString = itemIds.join("+");
            return method(
                map.serviceUrl + "?" + map.idNodeName + "=" + itemIdsString,
                S25Util.getPayload("notify_info", "notification_policy", "object_id", status, itemIdsString, {
                    approval_type: approvalTypeId,
                    respond_within: respondWithin,
                    contact: contacts.map(function (c: any) {
                        return {
                            status: c.status || "est",
                            contact_id: c.itemId,
                            notify_type: c.notifyType && c.notifyType.itemId,
                        };
                    }),
                }),
            );
        }
    }

    public static setBulk(
        itemTypeId: number,
        itemIds: number[], // Id for object
        approvalTypeId: number,
        respondWithin: string, //date string Duration
        addContacts: any[],
        removeContacts: any[],
        deleteAllContacts: boolean,
    ) {
        let url = "/notification/policy.json?itemTypeId=" + itemTypeId;
        let date = new Date(1900, 0, 1);
        let respondMinutes = S25Util.ISODurationToMinutes(respondWithin);
        let payload = {
            delete_all_contacts: deleteAllContacts ? 1 : 0, //usually true,
            approval_type: approvalTypeId, //parent approval type,
            respond_within: respondWithin && S25Util.date.dropTZString(S25Util.date.addMinutes(date, respondMinutes)),
            objects:
                itemIds &&
                itemIds.length &&
                itemIds.map((itemId) => {
                    return { object_id: itemId };
                }),
            contactsAdd:
                addContacts &&
                addContacts.length &&
                addContacts.map((c) => {
                    return {
                        contact_id: c.itemId || c.contact_id,
                        notify_type: (c.notifyType && c.notifyType.itemId) || c.notify_type,
                    };
                }),
            contactsRemove:
                removeContacts &&
                removeContacts.length &&
                removeContacts.map((c) => {
                    return {
                        contact_id: c.itemId,
                    };
                }),
        };
        return DataAccess.put(url, { root: payload });
    }

    public static copy(itemTypeId: number, srcId: number, dstId: number) {
        return NotificationPolicyService.get(itemTypeId, srcId).then((resp) => {
            let date = S25Util.ISODurationToObj(resp.respond_within);
            let contacts =
                (resp &&
                    resp.contact &&
                    resp.contact.map((cont: any) => {
                        return {
                            itemId: cont.contact_id,
                            notifyType: { itemId: cont.notify_type },
                            status: "new",
                        };
                    })) ||
                [];

            if (resp) {
                return NotificationPolicyService.set(
                    itemTypeId,
                    [dstId],
                    resp.approval_type,
                    date.days,
                    date.hours,
                    date.minutes,
                    contacts,
                    true,
                    false,
                );
            }
        });
    }

    public static deleteBulk(itemTypeId: number, itemIds: number[]) {
        let url = "/notification/policy.json?itemTypeId=" + itemTypeId;
        let payload = {
            approval_type: 0, //no notification policy
            objects:
                itemIds &&
                itemIds.length &&
                itemIds.map((itemId) => {
                    return { object_id: itemId };
                }),
        };
        return DataAccess.delete(url, { root: payload });
    }

    public static updateNotifyTypeOnly(
        itemTypeId: number,
        itemIds: number[],
        notifyTypeOnly: number,
        notifyType: number, // Id for object
    ) {
        let url =
            "/notification/policy.json?itemTypeId=" +
            itemTypeId +
            "&isNotifyTypeOnly=" +
            notifyTypeOnly +
            "&notifyType=" +
            notifyType;
        let addContacts: any[];
        let removeContacts: any[];
        let payload = {
            delete_all_contacts: 0, //usually true,
            objects:
                itemIds &&
                itemIds.length &&
                itemIds.map((itemId) => {
                    return { object_id: itemId };
                }),
            contactsAdd: addContacts,
            contactsRemove: removeContacts,
        };
        return DataAccess.put(url, { root: payload });
    }
}

// {
//     delete_all_contacts: 1|0
//     approval_type: ###
//     respond_within: datetime, <-- this is based off of 1900-01-01THH:MM:SS so perhaps just send in the date like that
//     objects: [
//        { object_id: ### }, { object_id: ### }, ...
//     ],
//     contactsAdd: [
//        { contact_id: ###, notify_type: ### }, { contact_id: ###, notify_type: ### }, ...
//     ],
//     contactsRemove: [
//        { contact_id: ### }, { contact_id: ### }, ...
//     ],
//  }
