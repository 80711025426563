//@author devin
import { MasterDefinition } from "../master.definition";

export class LocationLayoutService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rmconf.json";
    private static rootNodeName = "space_layouts";
    private static itemNodeName = "layout";
    private static idNodeName = "layout_id";
    private static nameNodeName = "layout_name";

    public constructor() {
        super(
            LocationLayoutService.serviceUrl,
            LocationLayoutService.rootNodeName,
            LocationLayoutService.itemNodeName,
            LocationLayoutService.idNodeName,
            LocationLayoutService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (LocationLayoutService.singleton == null) {
            LocationLayoutService.singleton = new LocationLayoutService();
        }
        return LocationLayoutService.singleton;
    }
}
