//@author devin
import { MasterDefinition } from "../master.definition";

export class EventTypeService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/evtype.json?node_type=E";
    private static rootNodeName = "event_types";
    private static itemNodeName = "event_type";
    private static idNodeName = "type_id";
    private static nameNodeName = "type_name";

    public constructor() {
        super(
            EventTypeService.serviceUrl,
            EventTypeService.rootNodeName,
            EventTypeService.itemNodeName,
            EventTypeService.idNodeName,
            EventTypeService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (EventTypeService.singleton == null) {
            EventTypeService.singleton = new EventTypeService();
        }
        return EventTypeService.singleton;
    }
}
