//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";

export class MergeSearchService {
    @Cache({ targetName: "MergeSearchService", expireTimeMs: 900000 })
    public static preCache(componentId: number, compParams: any) {
        let paramStr = "";
        let idx = 0;
        if (componentId) {
            paramStr += "?itemId=" + componentId;
            idx++;
        }
        jSith.forEach(compParams, (key: any, value: any) => {
            if (value === 0 || value) {
                paramStr += (idx === 0 ? "?" : "&") + key + "=" + value;
            }
            idx++;
        });
        return DataAccess.put("/merge/search/precache.json" + paramStr);
    }

    public static getCacheId(componentId: number, type: "search" | "", compParams: any) {
        let paramStr = "?itemId=" + componentId;
        jSith.forEach(compParams, (key: any, value: any) => {
            if (value === 0 || value) {
                paramStr += "&" + key + "=" + value;
            }
        });
        paramStr += "&typeId=" + type;
        return DataAccess.get("/merge/search/cache.json" + paramStr).then((data) => {
            return data && S25Util.propertyGetVal(data, "cache_id");
        });
    }
}
