//@author devin
import { MasterDefinitionUtil } from "../../../util/master.definition.util";
import { MasterDefinition } from "../master.definition";

export class RateGroupService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rategrp.json";
    private static rootNodeName = "rate_groups";
    private static itemNodeName = "rate_group";
    private static idNodeName = "rate_group_id";
    private static nameNodeName = "rate_group_name";

    public constructor() {
        super(
            RateGroupService.serviceUrl,
            RateGroupService.rootNodeName,
            RateGroupService.itemNodeName,
            RateGroupService.idNodeName,
            RateGroupService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (RateGroupService.singleton == null) {
            RateGroupService.singleton = new RateGroupService();
        }
        return RateGroupService.singleton;
    }

    public setGroupCode(itemId: number, code: any) {
        return MasterDefinitionUtil.setValue(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            "organization_code",
            itemId,
            code,
        );
    }
}
