//@author devin
import { MasterDefinition } from "../master.definition";

export class OrganizationRatingService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/orgrtg.json";
    private static rootNodeName = "organization_ratings";
    private static itemNodeName = "rating";
    private static idNodeName = "rating_id";
    private static nameNodeName = "rating_name";

    public constructor() {
        super(
            OrganizationRatingService.serviceUrl,
            OrganizationRatingService.rootNodeName,
            OrganizationRatingService.itemNodeName,
            OrganizationRatingService.idNodeName,
            OrganizationRatingService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (OrganizationRatingService.singleton == null) {
            OrganizationRatingService.singleton = new OrganizationRatingService();
        }
        return OrganizationRatingService.singleton;
    }
}
