//@author: devin

import { OrganizationRatingService } from "./organization.misc/organization.rating.service";
import { OrganizationTypeService } from "./organization.misc/organization.type.service";
import { OrganizationCategoryService } from "./categories/organization.category.service";
import { EventRequirementService } from "./event.misc/event.requirement.service";
import { EventCategoryService } from "./categories/event.category.service";
import { LocationCategoryService } from "./categories/location.category.service";
import { ResourceCategoryService } from "./categories/resource.category.service";
import { LocationLayoutService } from "./location.misc/location.layout.service";
import { LocationPartitionService } from "./location.misc/location.partition.service";
import { EventRoleService } from "./event.misc/event.role.service";
import { OrganizationRoleService } from "./organization.misc/organization.role.service";
import { LocationFeatureService } from "./location.misc/location.feature.service";
import { DataAccess } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";
import { CustomAttributeEventService } from "./custom.attributes/custom.attribute.event.service";
import { CustomAttributeLocationService } from "./custom.attributes/custom.attribute.location.service";
import { CustomAttributeContactService } from "./custom.attributes/custom.attribute.contact.service";
import { CustomAttributeOrganizationService } from "./custom.attributes/custom.attribute.organization.service";
import { CustomAttributeResourceService } from "./custom.attributes/custom.attribute.resource.service";
import { RateGroupService } from "./pricing/rate.group.service";
import { EventTypeService } from "./event.misc/event.type.service";
import { TelemetryService } from "../telemetry.service";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { S25Const } from "../../util/s25-const";

export class MasterDefinitionService {
    private static MethodMap: any = {};

    private static Services: any = [
        { name: "event_contact_role", service: EventRoleService.getSingleton() },
        { name: "org_contact_role", service: OrganizationRoleService.getSingleton() },
        { name: "org_type", service: OrganizationTypeService.getSingleton() },
        { name: "org_rating", service: OrganizationRatingService.getSingleton() },
        { name: "org_category", service: OrganizationCategoryService.getSingleton() },
        { name: "event_category", service: EventCategoryService.getSingleton() },
        { name: "location_category", service: LocationCategoryService.getSingleton() },
        { name: "resource_category", service: ResourceCategoryService.getSingleton() },
        { name: "other_requirement", service: EventRequirementService.getSingleton() },
        { name: "calendar_requirement", service: EventRequirementService.getSingleton() },
        { name: "event_custom_attribute", service: CustomAttributeEventService.getSingleton() },
        { name: "location_custom_attribute", service: CustomAttributeLocationService.getSingleton() },
        { name: "resource_custom_attribute", service: CustomAttributeResourceService.getSingleton() },
        { name: "org_custom_attribute", service: CustomAttributeOrganizationService.getSingleton() },
        { name: "contact_custom_attribute", service: CustomAttributeContactService.getSingleton() },
        { name: "layout", service: LocationLayoutService.getSingleton() },
        { name: "partition", service: LocationPartitionService.getSingleton() },
        { name: "feature", service: LocationFeatureService.getSingleton() },
        { name: "rate_group", service: RateGroupService.getSingleton() },
        { name: "event_type", service: EventTypeService.getSingleton() },
    ].forEach(function (entry: any) {
        let entryObj: any = {};
        let fns: string[] = [
            "get",
            "delete",
            "forceDelete",
            "setName",
            "setActive",
            "createNew",
            "setRateGroup",
            "setSchedule25",
            "setMultiVal",
            "setStockCount",
            "setReqComment",
            "setGroupCode",
        ];

        fns.forEach(function (fnName: string) {
            let serviceMethodName = fnName;
            if (fnName === "get" && entry.name === "other_requirement") {
                serviceMethodName = "getOther";
            } else if (fnName === "get" && entry.name === "calendar_requirement") {
                serviceMethodName = "getCalendar";
            }

            if (entry.service[serviceMethodName]) {
                entryObj[fnName] = function (arg1?: any, arg2?: any) {
                    return entry.service[serviceMethodName](arg1, arg2);
                };
            }
        });

        MasterDefinitionService.MethodMap[entry.name] = entryObj;
    });

    public static getServices() {
        return MasterDefinitionService.Services;
    }

    public static get(type: string) {
        return MasterDefinitionService.MethodMap[type].get();
    }

    public static delete(type: string, itemId: number) {
        return MasterDefinitionService.MethodMap[type].delete(itemId);
    }

    public static forceDelete(type: string, itemId: number) {
        return MasterDefinitionService.MethodMap[type].forceDelete(itemId);
    }

    public static setName(type: string, itemId: number, itemName: string) {
        return MasterDefinitionService.MethodMap[type].setName(itemId, itemName);
    }

    public static setActive(type: string, itemId: number, active: boolean) {
        return MasterDefinitionService.MethodMap[type].setActive(itemId, active);
    }

    public static createNew(type: string, paramMap?: any) {
        let eventType = "Create" + S25Util.firstCharToUpper(S25Util.snakeToCamel(type));
        TelemetryService.send("MasterDefn", eventType);
        return MasterDefinitionService.MethodMap[type].createNew(paramMap);
    }

    public static setSchedule25(type: string, itemId: number, schedule25: boolean) {
        return MasterDefinitionService.MethodMap[type].setSchedule25(itemId, schedule25);
    }

    public static setMultiVal(type: string, itemId: number, multiVal: boolean) {
        return MasterDefinitionService.MethodMap[type].setMultiVal(itemId, multiVal);
    }

    public static setStockCount(type: string, itemId: number, stockCount: boolean) {
        return MasterDefinitionService.MethodMap[type].setStockCount(itemId, stockCount);
    }

    public static setReqComment(type: string, itemId: number, comments: boolean) {
        return MasterDefinitionService.MethodMap[type].setReqComment(itemId, comments);
    }

    public static setRateGroup(type: string, itemId: number, rateGroup: any) {
        return MasterDefinitionService.MethodMap[type].setRateGroup(itemId, rateGroup);
    }

    public static setGroupCode(type: string, itemId: number, code: string) {
        return MasterDefinitionService.MethodMap[type].setGroupCode(itemId, code);
    }

    public static getUsage(masterListType: string, masterListId: number) {
        let objectType = "";

        if (masterListType.indexOf("event") > -1) {
            objectType = "event";
        } else if (masterListType.indexOf("location") > -1) {
            objectType = "space";
        } else if (masterListType.indexOf("resource") > -1) {
            objectType = "resource";
        } else if (masterListType.indexOf("org") > -1) {
            objectType = "organization";
        } else if (masterListType.indexOf("contact") > -1) {
            objectType = "contact";
        } else if (masterListType.indexOf("group") > -1) {
            masterListType = "group";
            objectType = "rate";
        }

        if (masterListType.indexOf("category") > -1) {
            masterListType = "category";
        } else if (masterListType.indexOf("role") > -1) {
            masterListType = "role";
        } else if (masterListType.indexOf("requirement") > -1) {
            masterListType = "requirement";
            objectType = "event";
        } else if (masterListType === "feature") {
            objectType = "space";
        } else if (masterListType === "layout") {
            objectType = "space";
        } else if (masterListType === "partition") {
            objectType = "space";
        } else if (masterListType === "org_type" || masterListType === "event_type") {
            masterListType = "type";
        } else if (masterListType === "org_rating") {
            masterListType = "rating";
        } else if (masterListType.indexOf("custom_attribute") > -1) {
            masterListType = "cust_atrb";
        }

        return DataAccess.get(
            "/master_list.json?master_list_id=" +
                masterListId +
                "&master_list_type=" +
                masterListType +
                "&object_type=" +
                objectType,
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, { object: true, search: true });
            data = data && data.master_list;
            if (data && data.object && data.object.length) {
                data.object = data.object.filter(S25Util.isNonEmptyBean);
            }
            if (data && data.search && data.search.length) {
                data.search = data.search.filter(S25Util.isNonEmptyBean);
                data.search = S25Util.array.uniqueByProp(data.search, "search_id");
            }
            return data;
        });
    }

    public static masterDefsConst: MasterDefConst = {
        calendar_requirement: {
            hasAllowComment: true,
            hasNotification: true,
            idProp: "requirement_id",
            prefs: [{ name: "config_ev_requirements", id: "abridged", header: "Abridged" }],
            nameProp: "requirement_name",
            nameLen: 80,
        },
        contact_custom_attribute: {
            hasType: true,
            idProp: "attribute_id",
            nameLen: 40,
            nameProp: "attribute_name",
        },
        event_category: {
            hasTags: true,
            idProp: "category_id",
            prefs: [{ name: "config_ev_categories", id: "abridged", header: "Abridged" }],
            nameProp: "category_name",
            nameLen: 80,
            tagProp: MasterDefTag.types.event_categories,
        },
        event_contact_role: {
            idProp: "role_id",
            prefs: [{ name: "config_ev_contact_roles", id: "abridged", header: "Abridged" }],
            nameProp: "role_name",
            nameLen: 40,
        },
        event_custom_attribute: {
            hasDiscrete: true,
            hasMultiVal: true,
            hasType: true,
            idProp: "attribute_id",
            prefs: [
                { name: "config_ev_cust_atrb_details", id: "evDetails", header: "Event Details" },
                { name: "config_ev_cust_atrb_wizard", id: "evForm", header: "Event Form" },
            ],
            nameProp: "attribute_name",
            nameLen: 120,
            blockNameEditIds: [-20, -21], // block editing names for 'Event Image' and 'Detail Image'
        },
        location_category: {
            hasTags: true,
            idProp: "category_id",
            prefs: [{ name: "config_sp_categories", id: "abridged", header: "Abridged" }],
            nameProp: "category_name",
            nameLen: 80,
            tagProp: MasterDefTag.types.location_categories,
        },
        location_custom_attribute: {
            hasType: true,
            idProp: "attribute_id",
            prefs: [{ name: "config_sp_cust_atrb", id: "abridged", header: "Abridged" }],
            nameLen: 120,
            nameProp: "attribute_name",
        },
        org_category: {
            hasTags: true,
            idProp: "category_id",
            prefs: [{ name: "config_org_categories", id: "abridged", header: "Abridged" }],
            nameProp: "category_name",
            nameLen: 80,
            tagProp: MasterDefTag.types.organization_categories,
        },
        org_contact_role: { idProp: "role_id", nameProp: "role_name", nameLen: 40 },
        org_custom_attribute: {
            hasType: true,
            idProp: "attribute_id",
            prefs: [{ name: "config_org_cust_atrb", id: "abridged", header: "Abridged" }],
            nameLen: 120,
            nameProp: "attribute_name",
        },
        other_requirement: {
            hasAllowQty: true,
            hasAllowComment: true,
            hasNotification: true,
            idProp: "requirement_id",
            prefs: [{ name: "config_ev_requirements", id: "abridged", header: "Abridged" }],
            nameProp: "requirement_name",
            nameLen: 80,
        },
        resource_category: {
            hasTags: true,
            idProp: "category_id",
            prefs: [{ name: "config_rs_categories", id: "abridged", header: "Abridged" }],
            nameProp: "category_name",
            nameLen: 80,
            tagProp: MasterDefTag.types.resource_categories,
        },
        resource_custom_attribute: {
            hasType: true,
            idProp: "attribute_id",
            prefs: [{ name: "config_rs_cust_atrb", id: "abridged", header: "Abridged" }],
            nameLen: 120,
            nameProp: "attribute_name",
        },
    } as const;

    public static custAttrTypes: CustAttrType<CustAttrTypeCodes>[] = [
        { itemName: S25Const.custAtrbType2Name["3"], typeCode: "3" },
        { itemName: S25Const.custAtrbType2Name["B"], typeCode: "B" },
        { itemName: S25Const.custAtrbType2Name["D"], typeCode: "D" },
        { itemName: S25Const.custAtrbType2Name["E"], typeCode: "E" },
        { itemName: S25Const.custAtrbType2Name["R"], typeCode: "R" },
        { itemName: S25Const.custAtrbType2Name["F"], typeCode: "F" },
        { itemName: S25Const.custAtrbType2Name["I"], typeCode: "I" },
        { itemName: S25Const.custAtrbType2Name["N"], typeCode: "N" },
        { itemName: S25Const.custAtrbType2Name["4"], typeCode: "4" },
        { itemName: S25Const.custAtrbType2Name["X"], typeCode: "X" },
        { itemName: S25Const.custAtrbType2Name["2"], typeCode: "2" },
        { itemName: S25Const.custAtrbType2Name["6"], typeCode: "6" },
        { itemName: S25Const.custAtrbType2Name["S"], typeCode: "S" },
        { itemName: S25Const.custAtrbType2Name["T"], typeCode: "T" },
    ];
}

export type CustAttrTypeCodes = keyof typeof S25Const.custAtrbType2Name;
export type CustAttrType<T extends CustAttrTypeCodes> = {
    itemName: (typeof S25Const.custAtrbType2Name)[T];
    typeCode: T;
};

type MasterDefConst = {
    [key: string]: MasterDefData;
};

export type MasterDefType = Extract<keyof MasterDefConst, string>;

export type MasterDefData = {
    hasAllowComment?: boolean;
    hasAllowQty?: boolean;
    hasDiscrete?: boolean;
    hasMultiVal?: boolean;
    hasNotification?: boolean;
    hasTags?: boolean;
    hasType?: boolean;
    idProp: "attribute_id" | "category_id" | "requirement_id" | "role_id";
    prefs?: readonly { name: string; id: string; header: string }[]; // pref name for service call, id and header for table columns/rows config
    nameProp: "attribute_name" | "category_name" | "requirement_name" | "role_name";
    nameLen: number;
    tagProp?: MasterDefTag.types;
    blockNameEditIds?: number[];
};
