//@author devin
import { MasterDefinition } from "../master.definition";

export class EventCategoryService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/evcat.json";
    private static rootNodeName = "event_categories";
    private static itemNodeName = "category";
    private static idNodeName = "category_id";
    private static nameNodeName = "category_name";

    public constructor() {
        super(
            EventCategoryService.serviceUrl,
            EventCategoryService.rootNodeName,
            EventCategoryService.itemNodeName,
            EventCategoryService.idNodeName,
            EventCategoryService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (EventCategoryService.singleton == null) {
            EventCategoryService.singleton = new EventCategoryService();
        }
        return EventCategoryService.singleton;
    }
}
