//@author devin
import { MasterDefinition } from "../master.definition";

export class LocationPartitionService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rmpart.json";
    private static rootNodeName = "space_partitions";
    private static itemNodeName = "partition";
    private static idNodeName = "partition_id";
    private static nameNodeName = "partition_name";

    public constructor() {
        super(
            LocationPartitionService.serviceUrl,
            LocationPartitionService.rootNodeName,
            LocationPartitionService.itemNodeName,
            LocationPartitionService.idNodeName,
            LocationPartitionService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (LocationPartitionService.singleton == null) {
            LocationPartitionService.singleton = new LocationPartitionService();
        }
        return LocationPartitionService.singleton;
    }
}
