//@author: travis
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { ContactService } from "./contact.service";

export interface Lock {
    session_id?: string | number;
    lock_item_id?: string | number;
    lock_item_name?: string;
    lock_item_type?: string | number;
    lock_item_type_name?: string;
    lock_type?: string | number;
    last_mod_user?: string;
    last_mod_dt?: string;
    email_address?: string;
    contact_phone?: string | number;
    contact_name?: string;
    event_locator?: string;
    disabled?: boolean;
}

export class LockService {
    public static unlock(itemTypeId: number, itemId: number) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/lock.json?lock_item_type=" + itemTypeId + "&lock_item_id=" + itemId,
                "LockService.unlock",
            ),
            null,
        );
    }

    public static lock(itemTypeId: number, itemId: number) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/lock.json?lock_item_type=" + itemTypeId + "&lock_item_id=" + itemId,
                "LockService.lock",
            ),
        ).then(
            function (data) {
                return S25Util.propertyGetVal(data, "msg_id") === "SY_I_SUCCESS";
            },
            function (error) {
                return false;
            },
        );
    }

    public static isLocked(itemTypeId: number, itemId: number) {
        return S25Util.all({
            currUsername: ContactService.getCurrentUsername(),
            lockData: LockService.getLockData(itemTypeId, itemId),
        }).then(function (resp) {
            if (resp.lockData) {
                //not locked if lock is for same user, else it is a lock
                return resp.lockData.last_mod_user !== resp.currUsername;
            } else {
                //error in service, interpret as locked
                return resp.lockData === "error";
            }
        });
    }

    public static checkAndSet(
        itemTypeId: number,
        itemId: any,
        successCallback: any,
        isLockedCallback: any,
        failureCallback: any,
    ) {
        successCallback = successCallback || jSith.noop;
        isLockedCallback = isLockedCallback || jSith.noop;
        failureCallback = failureCallback || jSith.noop;
        if (!!parseInt(itemId)) {
            return LockService.isLocked(itemTypeId, itemId).then(
                function (isLocked) {
                    if (isLocked) {
                        return isLockedCallback();
                    } else {
                        return LockService.lock(itemTypeId, itemId).then(
                            function (lockSuccess) {
                                if (lockSuccess) {
                                    return successCallback();
                                } else {
                                    return isLockedCallback();
                                }
                            },
                            function (error) {
                                return failureCallback();
                            },
                        );
                    }
                },
                function (error) {
                    return failureCallback();
                },
            );
        } else {
            return Promise.resolve(failureCallback());
        }
    }

    public static getLockData(itemTypeId: number, itemId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/lock.json?lock_item_type=" + itemTypeId + "&lock_item_id=" + itemId,
                "LockService.getLockData",
            ),
        ).then(
            function (data) {
                return (
                    data &&
                    data.locks &&
                    data.locks.lock &&
                    S25Util.array.forceArray(data.locks.lock).length &&
                    S25Util.array.forceArray(data.locks.lock)[0]
                );
            },
            function (error) {
                S25Util.showError(error);
                return "error";
            },
        );
    }

    public static getLocks(): Promise<Lock[]> {
        return DataAccess.get("/locks.json").then(function (data) {
            return data && data.locks && data.locks.lock && S25Util.array.forceArray(data.locks.lock);
        });
    }

    public static delete(lockItemId: number, lockItemType: number): Promise<any> {
        return DataAccess.delete(
            "/lock.json?lock_item_id=" + lockItemId + "&lock_item_type=" + lockItemType + "&delete_others=T",
        );
    }

    public static deleteLocks(remove: Lock[]) {
        return DataAccess.put(DataAccess.injectCaller("/lock.json?request_method=delete", "LockService.deleteLocks"), {
            locks: {
                lock: remove.map(function (a: any) {
                    return { lock_item_id: a.lock_item_id, lock_item_type: a.lock_item_type };
                }),
            },
        });
    }
}
