//variable to cache item names by id and type id
//note we do not use the service-cache bc we collect item names elsewhere and want an easy
//way to add them to the cache, eg via putItemToItemName (see framework.js)

import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { ContactService } from "./contact.service";
import { EventService } from "./event.service";
import { OrganizationService } from "./organization.service";
import { ResourceService } from "./resource.service";
import { SpaceService } from "./space.service";

var item2ItemName: any = {};
var DEFAULT_NAME = "Unknown";
export class NameService {
    public static normalizeItemTypeId(itemTypeId: any) {
        itemTypeId = parseInt(itemTypeId);
        if (itemTypeId >= 10) {
            if (S25Const.itemType[itemTypeId]) {
                return S25Const.itemName2Id[S25Const.itemType[itemTypeId].type];
            }
        }
        return itemTypeId;
    }

    @Timeout
    public static getFormal(itemId: any, itemTypeId: number) {
        itemId = parseInt(itemId);
        itemTypeId = NameService.normalizeItemTypeId(itemTypeId);
        var promise;
        switch (itemTypeId) {
            //note: event not covered here bc s25-event-summary has all this info and more and thus no callers should need events covered in this service
            //note: if we ever do include event here we need to cover its cache invalidation from event inline-editing
            case 4:
                promise = SpaceService.getSpaceFormal(itemId);
                break;
            case 2:
                promise = OrganizationService.getOrganizationFormal(itemId);
                break;
            case 3:
                promise = ContactService.getContactTitle(itemId);
                break;
        }
        return (
            promise &&
            promise.then(function (name) {
                return S25Util.coalesce(name, DEFAULT_NAME);
            })
        );
    }

    public static getName(itemId: any, itemTypeId: number) {
        itemId = parseInt(itemId);
        itemTypeId = NameService.normalizeItemTypeId(itemTypeId);
        var promise;
        if (item2ItemName[itemId] && item2ItemName[itemId][itemTypeId]) {
            return jSith.when(item2ItemName[itemId][itemTypeId]);
        } else {
            switch (itemTypeId) {
                case 1:
                    promise = EventService.getEventName(itemId);
                    break;
                case 4:
                    promise = SpaceService.getSpaceName(itemId);
                    break;
                case 6:
                    promise = ResourceService.getResourceName(itemId);
                    break;
                case 2:
                    promise = OrganizationService.getOrganizationName(itemId);
                    break;
                case 3:
                    promise = ContactService.getContactName(itemId);
                    break;
            }
            return (
                promise &&
                promise.then(function (name) {
                    NameService.putItemToItemName(itemId, itemTypeId, name);
                    return S25Util.coalesce(name, DEFAULT_NAME);
                })
            );
        }
    }

    public static putItemToItemName(itemId: any, itemTypeId: number, name?: string, onlyIfNotExists?: boolean) {
        name = S25Util.coalesce(name, DEFAULT_NAME);
        itemId = parseInt(itemId);
        itemTypeId = NameService.normalizeItemTypeId(itemTypeId);

        if (!item2ItemName[itemId]) {
            item2ItemName[itemId] = {};
        }

        if (onlyIfNotExists) {
            if (S25Util.isUndefined(item2ItemName[itemId][itemTypeId])) {
                item2ItemName[itemId][itemTypeId] = name;
            }
        } else {
            item2ItemName[itemId][itemTypeId] = name;
        }
    }
}
