import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";
import { Timeout } from "../decorators/timeout.decorator";

export interface ColorBucketType {
    is_enabled: 0 | 1;
    type: string;
    lang: any;
    closeModal: () => {};
    modalInstance: Element;
    isUnsupportedBrowser: boolean;
    buckets: ColorBucket[];
}

export interface ColorBucket {
    bucket_name: string;
    bucket_id: number;
    type: "Event Type" | "State";
    color: ItemColor;
    items: ColorBucketItem[];
}

export interface ItemColor {
    name: string;
    hash: string;
    textColor: string;
    pattern?: ColorScheme;
    id?: any;
    errors?: ItemColorErrors;
}

export interface ColorScheme {
    name: string;
    css: string;
}

export interface ItemColorErrors {
    noErrors: boolean;
    // errorMessage1: string
    // showError1: boolean
    [key: string]: boolean | string;
}

export interface ColorBucketItem {
    itemId: number;
    itemName: string;
}

export class ItemColorMappingService {
    public static getPatterns(): ColorScheme[] {
        return [
            {
                name: "Stripes",
                css: "url(./resources/typescript/assets/patterns/stripes.svg);",
            },
            {
                name: "Diagonal Stripes",
                css: "url(./resources/typescript/assets/patterns/diagonals.svg);",
            },
            {
                name: "Zig Zag",
                css: "url(./resources/typescript/assets/patterns/zigzag.svg);",
            },
            {
                name: "Waves",
                css: "url(./resources/typescript/assets/patterns/waves.svg);",
            },
            {
                name: "Dots",
                css: "url(./resources/typescript/assets/patterns/dots.svg);",
            },
            {
                name: "Checks",
                css: "url(./resources/typescript/assets/patterns/checks.svg);",
            },
            {
                name: "Diamonds",
                css: "url(./resources/typescript/assets/patterns/diamonds.svg);",
            },
        ];
    }

    public static getPatternByName(name: string) {
        const patterns = this.getPatterns();
        const pattern = patterns.find((pattern) => pattern.name === name);
        return pattern?.css ?? "";
    }

    @Timeout
    public static _getColorSchemes(): Promise<{ schemes: ColorScheme[] }> {
        return DataAccess.get("/availability/color/schemes.json");
    }

    @Timeout
    @Cache({ immutable: true, targetName: "ItemColorMappingService" })
    public static getColorSchemes() {
        return ItemColorMappingService._getColorSchemes().then((data) => {
            let colors: ItemColor[] = [];
            jSith.forEach(data?.schemes, (_, scheme) => {
                let color: ItemColor = {
                    name: scheme.scheme_name,
                    hash: scheme.color_hash,
                    textColor: scheme.text_color,
                    id: scheme.scheme_id,
                    errors: {
                        noErrors: true,
                        errorMessage1: "Please assign a name to each color scheme",
                        showError1: false,
                        errorMessage2: "Please use a unique name for each color scheme",
                        showError2: false,
                        errorMessage3:
                            "Please adjust your chosen colors so they have enough contrast to pass WCAG accessibility requirements. For more information and to test your colors, visit a <a href='https://webaim.org/resources/contrastchecker/' target='_blank'>color contrast checker</a>.",
                        showError3: false,
                    },
                };
                if (scheme.pattern) {
                    let css = this.getPatternByName(scheme.pattern);
                    color.pattern = { name: scheme.pattern, css: css };
                }
                colors.push(color);
            });
            return colors;
        });
    }

    @Timeout
    @Invalidate({ methodName: "ItemColorMappingService.getColorSchemes" })
    public static putColorSchemes(schemes: ColorScheme[]) {
        return DataAccess.put("/availability/color/schemes.json", { schemes });
    }

    @Timeout
    public static _getBucketTypes(): Promise<{ types: ColorBucketType[] }> {
        return DataAccess.get("/availability/colors.json");
    }

    @Timeout
    @Cache({ immutable: true, targetName: "ItemColorMappingService" })
    public static getBucketTypes() {
        return S25Util.all({
            bucketTypes: ItemColorMappingService._getBucketTypes(),
            colorSchemes: this.getColorSchemes(),
        }).then((resp: { bucketTypes: { types: ColorBucketType[] }; colorSchemes: ItemColor[] }) => {
            const colorMap: { [key: string]: ItemColor } = {};
            for (let color of resp.colorSchemes || []) colorMap[color.name] = color;
            for (let bucketType of resp.bucketTypes?.types ?? []) {
                for (let bucket of bucketType.buckets ?? []) {
                    const color = colorMap[bucket.color.name];
                    if (!color) continue;
                    bucket.color.hash = color.hash;
                    bucket.color.textColor = color.textColor;
                    if (color.pattern?.name)
                        bucket.color.pattern = { name: color.pattern.name, css: color.pattern.css };
                    bucket.color.hash = color.hash;
                    bucket.color.textColor = color.textColor;
                }
            }
            return resp.bucketTypes;
        });
    }

    @Timeout
    public static getEnabledBucketType() {
        return this.getBucketTypes().then((data) => {
            if (!data?.types) return;
            return data.types.find((type) => S25Util.toBool(type.is_enabled));
        });
    }

    @Timeout
    @Invalidate({ methodName: "ItemColorMappingService.getBucketTypes" })
    public static putBucketTypes(types: ColorBucketType[]) {
        return DataAccess.put("/availability/colors.json", types);
    }

    @Timeout
    public static checkSchemeUse(name: string) {
        return ItemColorMappingService._getBucketTypes().then((resp) => {
            let usedIn = [];
            if (!resp?.types) return [];
            for (let type of resp.types) {
                for (let bucket of type.buckets) {
                    if (bucket.color.name === name) {
                        usedIn.push(bucket);
                    }
                }
            }
            return usedIn;
        });
    }

    public static async loadCss() {
        const colorMap = await ItemColorMappingService.getEnabledBucketType();
        const css = ItemColorMappingService.compileCss(colorMap);
        const style = document.createElement("style");
        style.appendChild(document.createTextNode(css));
        style.id = "ItemColorMappingService";
        document.getElementById("ItemColorMappingService")?.remove();
        document.head.appendChild(style);
    }

    public static compileCss(colorMap: ColorBucketType) {
        if (!colorMap?.buckets?.length) return "";

        const rules = [];
        for (const bucket of colorMap.buckets) {
            if (!bucket.items) continue;
            const type = bucket.type.toLowerCase().replace(/\s+/g, "-");
            const items = bucket.items.map((item) => `[data-${type}="${item.itemId}"]`);
            const eventPattern = bucket.color.pattern?.css.replace(/;$/, "") || "none";

            const rule = `
.grid-color:is(${items.join(",")}) {
    &:where([data-type="event"], [data-type="requested"], [data-type="draft"]) {
        --grid-color--background-color: ${bucket.color.hash};
        --grid-color--color: ${bucket.color.textColor};
    }
    
    &[data-type="event"] {
        background-image: ${eventPattern};
    }
}
`;
            rules.push(rule);
        }

        return rules.join("\n");
    }

    public static getColor(colorMap: ColorBucketType, state: number, type: number) {
        if (!colorMap?.buckets) return;

        for (const bucket of colorMap.buckets) {
            const value = bucket.type === "Event Type" ? type : state;
            const match = bucket.items?.some((item) => item.itemId === value);
            if (match) return bucket;
        }
    }
}
