//@author devin
import { MasterDefinition } from "../master.definition";
import { MasterDefinitionUtil } from "../../../util/master.definition.util";

export class CustomAttributeEventService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/evatrb.json";
    private static rootNodeName = "event_custom_attributes";
    private static itemNodeName = "attribute";
    private static idNodeName = "attribute_id";
    private static nameNodeName = "attribute_name";

    public constructor() {
        super(
            CustomAttributeEventService.serviceUrl,
            CustomAttributeEventService.rootNodeName,
            CustomAttributeEventService.itemNodeName,
            CustomAttributeEventService.idNodeName,
            CustomAttributeEventService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (CustomAttributeEventService.singleton == null) {
            CustomAttributeEventService.singleton = new CustomAttributeEventService();
        }
        return CustomAttributeEventService.singleton;
    }

    public setMultiVal(itemId: number, multiVal: boolean) {
        return MasterDefinitionUtil.setValues(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            itemId,
            {
                multi_val: multiVal ? "T" : "F",
            },
        );
    }
}
