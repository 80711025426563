//@author devin
import { MasterDefinition } from "../master.definition";

export class ResourceCategoryService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rscat.json";
    private static rootNodeName = "resource_categories";
    private static itemNodeName = "category";
    private static idNodeName = "category_id";
    private static nameNodeName = "category_name";

    public constructor() {
        super(
            ResourceCategoryService.serviceUrl,
            ResourceCategoryService.rootNodeName,
            ResourceCategoryService.itemNodeName,
            ResourceCategoryService.idNodeName,
            ResourceCategoryService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (ResourceCategoryService.singleton == null) {
            ResourceCategoryService.singleton = new ResourceCategoryService();
        }
        return ResourceCategoryService.singleton;
    }
}
