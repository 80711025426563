//@author devin
import { MasterDefinition } from "../master.definition";

export class OrganizationRoleService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/orgcr.json";
    private static rootNodeName = "organization_roles";
    private static itemNodeName = "role";
    private static idNodeName = "role_id";
    private static nameNodeName = "role_name";

    public constructor() {
        super(
            OrganizationRoleService.serviceUrl,
            OrganizationRoleService.rootNodeName,
            OrganizationRoleService.itemNodeName,
            OrganizationRoleService.idNodeName,
            OrganizationRoleService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (OrganizationRoleService.singleton == null) {
            OrganizationRoleService.singleton = new OrganizationRoleService();
        }
        return OrganizationRoleService.singleton;
    }
}
