//@author devin
import { MasterDefinition } from "../master.definition";

export class CustomAttributeResourceService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/resat.json";
    private static rootNodeName = "resource_custom_attributes";
    private static itemNodeName = "attribute";
    private static idNodeName = "attribute_id";
    private static nameNodeName = "attribute_name";

    public constructor() {
        super(
            CustomAttributeResourceService.serviceUrl,
            CustomAttributeResourceService.rootNodeName,
            CustomAttributeResourceService.itemNodeName,
            CustomAttributeResourceService.idNodeName,
            CustomAttributeResourceService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (CustomAttributeResourceService.singleton == null) {
            CustomAttributeResourceService.singleton = new CustomAttributeResourceService();
        }
        return CustomAttributeResourceService.singleton;
    }
}
