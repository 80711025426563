let notifications: any = [];
let msgId = 0;
let PREPEND = "S25Notification-";

export class NotificationService {
    //Just pass through any Ang notification posts to angJs until it makes sense to migrate all of notifications to TS
    public static post(msg: string, retention?: any, notificationClass?: any) {
        window.angBridge.$injector.get("NotificationService").post(msg, retention, notificationClass);
    }
    public static postBarNotification(
        message: string,
        isSticky: boolean,
        showClose: boolean,
        showButton: boolean,
        buttonFn: Function,
        buttonMsg: string,
    ) {
        window.angBridge.$injector
            .get("NotificationService")
            .postBarNotification(message, isSticky, showClose, showButton, buttonFn, buttonMsg);
    }

    // public static getAll(){
    //     return notifications;
    // }

    // public static removeById(id: number|string){
    //     let n = notifications.filter((a: any)=>{
    //         return n.id === id;
    //     });
    //     n && n.length && NotificationService.removeById(n[0]);
    // }

    // public static remove(notificationObj: any){
    //     let idx = notifications.indexOf(notificationObj);
    //     if(idx > -1){
    //         notifications.splice(idx, 1);
    //     }
    // }
}
