import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import * as Sentry from "@sentry/angular";
import { S25Const } from "../util/s25-const";
import { Proto } from "../pojo/Proto";
import ISODateString = Proto.ISODateString;
import URLString = Proto.URLString;
import NumericalString = Proto.NumericalString;
import CharBoolean = Proto.CharBoolean;
import { LooseAutocomplete } from "../pojo/Util";

export class LoginService {
    public static cachedLoginData: any = null;
    public static lastPubDate: Date = null;

    public static getCachedLoginData() {
        return LoginService.cachedLoginData as ReturnType<typeof LoginService.extractLoginData>;
    }

    public static extractLoginData(json: any) {
        LoginService.lastPubDate =
            S25Util.date.parseDropTZ(S25Util.propertyGetVal(json, "pubdate")) || LoginService.lastPubDate;
        let resp: any = {
            isLoggedIn: S25Util.propertyGetVal(json, "success") === "T",
            challenge: S25Util.propertyGetVal(json, "challenge") as string,
            loginUrl: S25Util.propertyGetVal(json, "login_url") as URLString,
            logoutUrl: S25Util.propertyGetVal(json, "logout_url") as URLString,
            errorUrl: S25Util.propertyGetVal(json, "error_url") as URLString,
            pubdate: LoginService.lastPubDate,
            contactName: (S25Util.propertyGetVal(json, "contact_name") as string) || "Guest",
            userName: (S25Util.propertyGetVal(json, "username") as string) || "Guest",
            userId: S25Util.propertyGetVal(json, "user_id") as NumericalString,
            securityGroupName: S25Util.propertyGetVal(json, "security_group_name") as string,
            securityGroupId: S25Util.propertyGetVal(json, "security_group_id") as NumericalString,
        };

        // series25.com loginUrl needs the prefix
        if (S25Const.isHostUriSeries25 && resp.loginUrl?.indexOf("shibboleth") > -1) {
            if (resp.loginUrl.indexOf(`/${S25Util.instanceId}/`) > -1) {
                resp.loginUrl = resp.loginUrl.replace(`/${S25Util.instanceId}/`, `/${S25Util.instanceIdWithPrefix}/`);
                resp.logoutUrl = resp.logoutUrl?.replace(
                    `/${S25Util.instanceId}/`,
                    `/${S25Util.instanceIdWithPrefix}/`,
                );
                resp.errorUrl = resp.errorUrl?.replace(`/${S25Util.instanceId}/`, `/${S25Util.instanceIdWithPrefix}/`);
            }
        }

        return resp;
    }

    public static formLoginData(json: any) {
        const loginData = LoginService.extractLoginData(json);
        LoginService.cacheLoginData(loginData);
        return LoginService.getCachedLoginData();
    }

    private static cacheLoginData(data: ReturnType<typeof LoginService.extractLoginData>) {
        LoginService.cachedLoginData = data;

        // Set user in Sentry
        const { userName, userId, securityGroupId, securityGroupName, isLoggedIn } = LoginService.cachedLoginData;
        let sentryUser: Parameters<typeof Sentry.setUser>[0] = { username: "Guest" };
        if (isLoggedIn) {
            sentryUser = {
                id: userId,
                username: userName,
                secGroup: securityGroupId,
                secGroupName: securityGroupName,
            };
        }
        Sentry.setUser(sentryUser);
    }

    /**
     * Fetches login data for the current user.
     *
     * If already logged in, returns user data
     *
     * If not logged in, returns a challenge
     * @Uncached
     */
    public static getLoginRaw() {
        return DataAccess.get<LoginResponse>("/login.json");
    }

    /**
     * Fetches login data
     * @Uncached
     */
    public static getLogin() {
        return LoginService.getLoginRaw().then(function (data) {
            return LoginService.formLoginData(S25Util.prettifyJson(data));
        });
    }

    @Cache({ targetName: "LoginService", hitNotify: true })
    /**
     * Cached wrapper of LoginService.getLogin()
     * @Cached
     */
    public static getLoginCached() {
        return LoginService.getLogin();
    }

    public static getLoggedIn(): Promise<boolean> {
        return LoginService.getLoginCached().then(function (data) {
            return data.isLoggedIn;
        });
    }

    public static getLoginFromSessionId(sessionId: string) {
        return DataAccess.get("/login.json?session_id=" + sessionId).then(function (data) {
            return LoginService.extractLoginData(S25Util.prettifyJson(data));
        });
    }
}

export type LoginResponse = LoginResponseLoggedIn | LoginResponseChallenge;

export type LoginResponseLoggedIn = {
    login_response: {
        engine: "sws";
        pubdate: ISODateString;
        login: {
            contact_name: string;
            login_url: URLString;
            logout_url: URLString;
            message: string;
            security_group_id: NumericalString;
            security_group_name: string;
            success: CharBoolean;
            user_id: NumericalString;
            user_type: LooseAutocomplete<"r25">;
            username: string;
        };
    };
};

export type LoginResponseChallenge = {
    login_challenge: {
        pubdate: ISODateString;
        login: {
            challenge: string;
            response: "";
            username: "";
        };
    };
};
