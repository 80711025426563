//@author devin
import { MasterDefinition } from "../master.definition";

export class EventRoleService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/evcnrl.json";
    private static rootNodeName = "event_roles";
    private static itemNodeName = "role";
    private static idNodeName = "role_id";
    private static nameNodeName = "role_name";

    public constructor() {
        super(
            EventRoleService.serviceUrl,
            EventRoleService.rootNodeName,
            EventRoleService.itemNodeName,
            EventRoleService.idNodeName,
            EventRoleService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (EventRoleService.singleton == null) {
            EventRoleService.singleton = new EventRoleService();
        }
        return EventRoleService.singleton;
    }
}
