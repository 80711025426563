//@author devin
import { MasterDefinition } from "../master.definition";
import { MasterDefinitionUtil } from "../../../util/master.definition.util";

export class OrganizationTypeService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/orgtypes.json";
    private static rootNodeName = "organization_types";
    private static itemNodeName = "type";
    private static idNodeName = "type_id";
    private static nameNodeName = "type_name";

    public constructor() {
        super(
            OrganizationTypeService.serviceUrl,
            OrganizationTypeService.rootNodeName,
            OrganizationTypeService.itemNodeName,
            OrganizationTypeService.idNodeName,
            OrganizationTypeService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (OrganizationTypeService.singleton == null) {
            OrganizationTypeService.singleton = new OrganizationTypeService();
        }
        return OrganizationTypeService.singleton;
    }

    public setRateGroup(itemId: number, rateGroup: any) {
        return MasterDefinitionUtil.setValue(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            "rate_group",
            itemId,
            { rate_group_id: rateGroup.itemId, rate_group_name: rateGroup.itemName },
        );
    }
}
