//@author devin
import { S25Util } from "../util/s25-util";

export class ListStateService {
    public static listState: any = {};

    public static updateSort(listId: string | number) {
        if (
            ListStateService.listState[listId] &&
            ListStateService.listState[listId].cols &&
            ListStateService.listState[listId].sortState
        ) {
            for (let i = 0; i < ListStateService.listState[listId].cols.length; i++) {
                if (
                    ListStateService.listState[listId].cols[i].prefname ===
                    ListStateService.listState[listId].sortState.sort
                ) {
                    ListStateService.listState[listId].cols[i].order =
                        ListStateService.listState[listId].sortState.order;
                } else {
                    delete ListStateService.listState[listId].cols[i].order;
                }
            }
        }
    }

    public static updateChecked(listId: string | number, type: string, rows: any[]) {
        //type can be --> refresh: refresh,     allChecked: set all to true,     allUnchecked: set all to false
        if (rows && rows.length) {
            let checkedObjs = ListStateService.getListChecked(listId); //get saved list of checked ids
            for (let i = 0; i < rows.length; i++) {
                //loop through data rows
                rows[i].checked =
                    type === "refresh" ? checkedObjs.indexOf(rows[i].contextId) > -1 : type === "allChecked"; //if data row in checked list, or this is all checked/all unchecked
            }
            ListStateService.setListChecked(listId, rows); //update saved list with our changes
        }
    }

    public static getListSortState(listId: string | number) {
        return ListStateService.listState[listId] && ListStateService.listState[listId].sortState;
    }

    public static setListSortState(listId: string | number, sortPrefName: string, sortDir: string) {
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].sortState = { sort: sortPrefName, order: sortDir };
        ListStateService.updateSort(listId);
    }

    public static toggleListSortState(listId: string | number, sortPrefName: string) {
        let currState = ListStateService.getListSortState(listId);
        if (!currState || currState.sort !== sortPrefName) {
            ListStateService.setListSortState(listId, sortPrefName, "asc");
        } else {
            ListStateService.setListSortState(listId, sortPrefName, currState.order === "asc" ? "desc" : "asc");
        }
        return ListStateService.getListSortState(listId);
    }

    public static getListPageSize(listId: string | number) {
        return ListStateService.listState[listId] && ListStateService.listState[listId].page_size;
    }

    public static setListPageSize(listId: string | number, page_size: number) {
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].page_size = page_size;
    }

    public static getListCols(listId: string | number) {
        return ListStateService.listState[listId] && ListStateService.listState[listId].cols;
    }

    public static setListCols(listId: string | number, cols: any[]) {
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].cols = cols;
        ListStateService.updateSort(listId);
    }

    public static getListChecked(listId: string | number) {
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].checkedRows = ListStateService.listState[listId].checkedRows || [];
        return ListStateService.listState[listId].checkedRows;
    }

    public static setListChecked(listId: string | number, rows: any[]) {
        //take data rows, update checked list based on them
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].checkedRows = ListStateService.listState[listId].checkedRows || [];
        if (rows && rows.length) {
            ListStateService.listState[listId].checkedRows = S25Util.array.unique(
                ListStateService.listState[listId].checkedRows,
            ); //unique list
            for (let i = 0; i < rows.length; i++) {
                let rowIndex = ListStateService.listState[listId].checkedRows.indexOf(rows[i].contextId); //row index of data row in saved checked list
                if (rows[i].checked) {
                    //if data is checked
                    ListStateService.listState[listId].checkedRows.push(rows[i].contextId); //push to saved checked list
                } else if (rowIndex > -1) {
                    //if data not checked but row is in saved list, delete it from saved list
                    ListStateService.listState[listId].checkedRows.splice(rowIndex, 1);
                }
            }
            ListStateService.listState[listId].checkedRows = S25Util.array.unique(
                ListStateService.listState[listId].checkedRows,
            ); //unique list again after adds / removes
        }
    }

    public static resetListChecked(listId: string | number) {
        ListStateService.listState[listId] = ListStateService.listState[listId] || {};
        ListStateService.listState[listId].checkedRows = [];
    }
}
