//@author devin
import { S25Util } from "../util/s25-util";
import { PreferenceService } from "./preference.service";
import { jSith } from "../util/jquery-replacement";

export class ListGeneratorService {
    public static generate(colsF: any, rowsF: any, metaF: any, dataService: any, footerF: any) {
        return function (dataParams: any) {
            var ret: any = {};
            return dataService(dataParams).then(function (listData: any) {
                //get list data
                ret.rows = [].concat(rowsF(listData));
                ret.footer = (footerF && footerF(listData)) || false;
                var metaData = metaF(listData);

                if (ret.rows && ret.rows[0]) {
                    //for database performance reasons, s25-list expects the first row to hold the page count and total result count in list data
                    ret.rows[0].pages = metaData.pageCount;
                    ret.rows[0].count = metaData.totalResultCount || ret.rows.length;
                }

                ret.cacheId = metaData.cacheId;
                ret.page = metaData.pageNumber;
                ret.page_size = dataParams && dataParams.modelBean && dataParams.modelBean.itemsPerPage;

                var colsPromise = colsF().then(function (colArray: any[]) {
                    ret.cols = [].concat(colArray);
                });

                return colsPromise.then(function () {
                    //set sort order in data...
                    jSith.forEach(ret.cols, function (_: any, obj: any) {
                        if (
                            dataParams &&
                            dataParams.modelBean &&
                            dataParams.modelBean.sortCol &&
                            obj.prefname === dataParams.modelBean.sortCol.sort
                        ) {
                            obj.order = dataParams.modelBean.sortCol.order;
                        } else {
                            delete obj.order;
                        }
                    });
                    return ret; //final return
                });
            });
        };
    }

    public static s25Generate(colPrefName: string, colsArray: any[], rowsF: any, dataService: any, footerF?: any) {
        var colsF = function () {
            var ret: any[] = [];
            var visibleCols: any = {};
            var colTemplate: any = {
                name: "",
                prefname: undefined,
                isDate: 0,
                isDateTime: 0,
                isPermanent: 0,
                isTime: 0,
                isVisible: 1,
                sortable: 0,
            };
            colsArray.map(function (obj) {
                visibleCols[obj.prefname || obj.prefId] = S25Util.coalesce(
                    S25Util.coalesce(obj.isVisible, obj.isDefaultVisible),
                    1,
                );
            });
            var colPrefPromise = colPrefName ? PreferenceService.getPreferences([colPrefName]) : jSith.when();
            return colPrefPromise.then(function (prefData: any) {
                if (prefData) {
                    prefData = S25Util.prettifyJson(prefData, null, { column: "true" }); //columns property should be an array
                    prefData = S25Util.propertyGet(prefData, "column");
                    jSith.forEach(prefData, function (_: any, obj: any) {
                        //pref objects have "name" property for "prefname"...
                        visibleCols[obj.name] = ["always", "true"].indexOf(obj.show) > -1 ? 1 : 0; //if any prefs exist, set them to true/false
                    });
                }

                jSith.forEach(colsArray, function (_: any, col: any) {
                    col.isVisible = visibleCols[col.prefname || col.prefId];
                    ret.push(S25Util.merge({}, S25Util.deepCopy(colTemplate), col));
                });

                return ret;
            });
        };

        var metaF = function (listData: any) {
            return {
                pageCount: parseInt(S25Util.propertyGet(listData, "page_count")),
                pageNumber: parseInt(S25Util.propertyGet(listData, "page_num")),
                totalResultCount: parseInt(S25Util.propertyGet(listData, "total_results")),
                cacheId: S25Util.propertyGet(listData, "paginate_key"),
            };
        };

        return ListGeneratorService.generate(colsF, rowsF, metaF, dataService, footerF);
    }
}
