//@author devin
import { MasterDefinition } from "../master.definition";
import { MasterDefinitionUtil } from "../../../util/master.definition.util";
import { S25Util } from "../../../util/s25-util";

export class EventRequirementService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/evreq.json";
    private static rootNodeName = "event_requirements";
    private static itemNodeName = "requirement";
    private static idNodeName = "requirement_id";
    private static nameNodeName = "requirement_name";

    public constructor() {
        super(
            EventRequirementService.serviceUrl,
            EventRequirementService.rootNodeName,
            EventRequirementService.itemNodeName,
            EventRequirementService.idNodeName,
            EventRequirementService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (EventRequirementService.singleton == null) {
            EventRequirementService.singleton = new EventRequirementService();
        }
        return EventRequirementService.singleton;
    }

    public getOther() {
        return this.get("?requirement_type=6");
    }

    public getCalendar() {
        return this.get("?requirement_type=7");
    }

    public setStockCount(itemId: number, stockCount: boolean) {
        return MasterDefinitionUtil.setValues(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            itemId,
            {
                stock_count: stockCount ? "0" : "-1",
            },
        );
    }

    public setReqComment(itemId: number, reqComment: boolean) {
        return MasterDefinitionUtil.setValues(
            this.serviceUrl,
            this.rootNodeName,
            this.itemNodeName,
            this.idNodeName,
            itemId,
            {
                allow_comment: reqComment ? "1" : "0",
            },
        );
    }

    public static getOtherStandardized() {
        return EventRequirementService.getSingleton()
            .get()
            .then((resp: any) => {
                resp = S25Util.array.forceArray(resp);
                return resp.map((item: any) => {
                    return {
                        itemId: item.requirement_id,
                        itemName: item.requirement_name,
                    };
                });
            });
    }
}
