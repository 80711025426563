import { DataAccess } from "../../dataaccess/data.access";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { S25Util } from "../../util/s25-util";
import { Item } from "../../pojo/Item";
import { jSith } from "../../util/jquery-replacement";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { Cache, Invalidate } from "../../decorators/cache.decorator";
import { Timeout } from "../../decorators/timeout.decorator";
import { UserprefService } from "../userpref.service";
import TagWrapperI = MasterDefTag.TagWrapperI;
import MdTag = MasterDefTag.MdTag;

/*
search criteria might not be named consistently throughout the app, so we need to map them to the correct name for use with tags
 */
function getSearchCriteriaName(md_name: string): SearchCriteriaType["type"] {
    switch (md_name) {
        case "event_categories":
        case "event_category": {
            return "eventCategories";
        }
        case "organization_categories":
        case "organization_category":
        case "org_categories":
        case "org_category": {
            return "organizationCategories";
        }
        case "location_categories":
        case "location_category": {
            return "locationCategories";
        }
        case "resource_categories":
        case "resource_category": {
            return "resourceCategories";
        }

        case "event_custom_atrb":
        case "event_cust_atrb": {
            return "eventCustomAttributes";
        }
        case "org_custom_atrb":
        case "org_cust_atrb": {
            return "organizationCustomAttributes";
        }
        case "contact_cust_atrb":
        case "contact_custom_atrb": {
            return "contactCustomAttribute";
        }
        case "location_custom_atrb":
        case "location_cust_atrb": {
            return "locationCustomAttributes";
        }
        case "resource_custom_atrb":
        case "resource_cust_atrb": {
            return "resourceCustomAttributes";
        }
        case "email_template": {
            return "manualEmailTemplates";
        }
        case "location_features":
        case "features": {
            return "locationFeatures";
        }
    }
}

function getDisplayName(md_name: string) {
    switch (md_name) {
        case "event_categories":
            return "Event Categories";
        case "org_categories":
            return "Organization Categories";
        case "location_categories":
            return "Location Categories";
        case "resource_categories":
            return "Resource Categories";
        case "email_template":
            return "Manual Email Templates";
        case "location_features":
            return "Features";
        default:
            return md_name;
    }
}

function getDbName(mdName: string): string {
    return mdName.endsWith("category")
        ? mdName.replace("category", "categories")
        : mdName.replace("feature", "location_features");
}

const emptyDetailsResponse = {
    members: [
        {
            md_name: "event_categories",
            active: 0,
        },
        {
            md_name: "org_categories",
            active: 0,
        },
        {
            md_name: "location_categories",
            active: 0,
        },
        {
            md_name: "resource_categories",
            active: 0,
        },
        {
            md_name: "location_features",
            active: 0,
        },
        {
            md_name: "email_template",
            active: 0,
        },
        // {
        //     md_name: "event_cust_atrb",
        //     active: 0,
        // },
        // {
        //     md_name: "org_cust_atrb",
        //     active: 0,
        // },
        // {
        //     md_name: "contact_cust_atrb",
        //     active: 0,
        // },
        // {
        //     md_name: "location_cust_atrb",
        //     active: 0,
        // },
        // {
        //     md_name: "resource_cust_atrb",
        //     active: 0,
        // },
    ],
};

export class MasterDefinitionTagsService {
    @Cache({ targetName: "MasterDefinitionTagsService", immutable: true })
    public static getTags(): Promise<MasterDefTag.TagWrapperI[]> {
        return UserprefService.getLoggedIn().then((isLoggedIn: boolean) => {
            if (!isLoggedIn) return [];
            let url = "/master/definition/tags/list.json";
            return DataAccess.get(DataAccess.injectCaller(url, "MasterDefinitionTagsService.getTags")).then((resp) => {
                //remove root from ANG-4518
                resp = resp.root || resp;
                return S25Util.array.forceArray(resp?.tags);
            });
        });
    }

    @Timeout
    public static getTag(
        tagId: number,
        itemTypeId?: Item.Data,
        itemName?: "categories" | "customAttributes",
    ): Promise<MasterDefTag.TagWrapperI[]> {
        let url = `/master/definition/tags/details.json?tag_id=${tagId}`;
        !!itemTypeId && url.concat(`&itemTypeId=${itemTypeId}`);
        !!itemName && url.concat(`&itemName=${itemName}`);

        let resp: Promise<any>;
        if (tagId) {
            resp = DataAccess.get(DataAccess.injectCaller(url, "MasterDefinitionTagsService.getTag"));
        } else {
            //return a copy of this object to make sure there's no leaking when editing new tags
            resp = jSith.resolve(
                S25Util.deepCopy({
                    tags: [
                        {
                            tag_name: "",
                            secGroups: [],
                            members: emptyDetailsResponse.members,
                        },
                    ],
                }),
            );
        }

        return resp.then((resp) => {
            let ret = S25Util.array.forceArray(resp?.tags) as MasterDefTag.TagWrapperI[];
            ret.forEach((tag: TagWrapperI) => {
                tag.secGroups = S25Util.array.forceArray(tag.secGroups).map((group: any) => {
                    return {
                        itemName: group.groupName,
                        itemId: group.groupId,
                    };
                });

                jSith.forEach(tag.members, (key, member) => {
                    member.searchCriteriaName = getSearchCriteriaName(member.md_name);
                    member.formalName = getSearchCriteriaName(member.md_name);
                    member.displayName = getDisplayName(member.md_name);
                    member.active = !!member.active;
                    member.items = S25Util.array.forceArray(member.items);
                });
            });

            return ret;
        });
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static editTagsOnObject(
        mdType: MasterDefTag.types,
        itemTypeId: number,
        mdId: number,
        addedTagIds: number[],
        removedTagsIds: number[],
    ) {
        const payload = {
            mlType: getDbName(mdType),
            itemId: mdId,
            addedTags: addedTagIds,
            removedTags: removedTagsIds,
        };

        const url = `/master/definition/tags/bulk.json`; //?itemTypeId=${itemTypeId}&mlType=${mdType}`;
        return DataAccess.put(DataAccess.injectCaller(url, "MasterDefinitionTagsService.updateTag"), {
            root: { tags: payload },
        });
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static updateTag(tagId: number, payload: TagWrapperI) {
        payload.members = payload.members.filter((md) => {
            return md.changed || md.addedItems?.length || md.removedItems?.length;
        });

        payload.members = payload.members.map((item) => {
            return {
                md_name: item.md_name,
                active: item.active,
                addedItems: item.addedItems?.map((add) => {
                    return { itemName: add.itemName, itemId: add.itemId };
                }),
                removedItems: item.removedItems?.map((remove) => {
                    return { itemName: remove.itemName, itemId: remove.itemId };
                }),
                items: [],
            };
        });

        let url = `/master/definition/tags.json`;
        url = !!tagId ? `${url}?tag_id=${tagId}` : url;
        return DataAccess.put(DataAccess.injectCaller(url, "MasterDefinitionTagsService.updateTag"), {
            root: { tags: payload },
        });
    }

    @Invalidate({ serviceName: "MasterDefinitionTagsService", methodName: "getTags" })
    public static deleteTag(tagId: number | string) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                `/master/definition/tags.json?tag_id=${tagId}`,
                "MasterDefinitionTagsService.delete",
            ),
        );
    }

    public static isItemReadOnly(tags: MdTag[]) {
        return S25Util.array.forceArray(tags).every((tag: MdTag) => !tag.readOnly);
    }
}
