//@author devin
import { MasterDefinition } from "../master.definition";

export class CustomAttributeLocationService extends MasterDefinition {
    private static singleton: MasterDefinition = null;

    private static serviceUrl = "/rmat.json";
    private static rootNodeName = "space_custom_attributes";
    private static itemNodeName = "attribute";
    private static idNodeName = "attribute_id";
    private static nameNodeName = "attribute_name";

    public constructor() {
        super(
            CustomAttributeLocationService.serviceUrl,
            CustomAttributeLocationService.rootNodeName,
            CustomAttributeLocationService.itemNodeName,
            CustomAttributeLocationService.idNodeName,
            CustomAttributeLocationService.nameNodeName,
        );
    }

    public static getSingleton() {
        if (CustomAttributeLocationService.singleton == null) {
            CustomAttributeLocationService.singleton = new CustomAttributeLocationService();
        }
        return CustomAttributeLocationService.singleton;
    }
}
